import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { Box, Button, HStack, PlusIcon, Text, useIsSmallScreen } from '@playful/design_system';
import { customEvent } from '@playful/telemetry';
import React from 'react';
import { buildUserRoute } from '../../paths';
import { useProjectTemplates } from './ProjectTemplates';
import { useRouter } from '../../hooks/useRouter';
export var lightBlankProjectRoute = {
  pathname: buildUserRoute('play', 'portrait-light', 'edit'),
  query: {
    fork: true,
    title: 'Untitled'
  }
};
export var darkBlankProjectRoute = {
  pathname: buildUserRoute('play', 'portrait-dark', 'edit'),
  query: {
    fork: true,
    title: 'Untitled'
  }
};
export function ProjectTemplatesFooter(props) {
  var _useProjectTemplates = useProjectTemplates(),
    hasShadow = _useProjectTemplates.hasShadow;
  var _useRouter = useRouter(),
    push = _useRouter.push;
  function newBlank() {
    customEvent('templatepicker-blankpage');
    push(lightBlankProjectRoute);
  }
  var isSmScreen = useIsSmallScreen(true);
  return __jsx(Box, _extends({
    position: "sticky",
    bg: "white",
    justifyContent: 'flex-start',
    bottom: 0,
    zIndex: "sticky",
    pt: 4,
    pb: 4,
    pl: 4,
    boxShadow: hasShadow ? 'lg-up' : 'none'
  }, props), __jsx(HStack, {
    alignItems: "center",
    display: "flex",
    px: {
      base: 6,
      md: 6
    },
    minH: 12,
    position: "sticky"
  }, __jsx(Button, {
    size: isSmScreen ? 'sm' : 'md',
    variant: "outline",
    color: "gray.900",
    leftIcon: __jsx(PlusIcon, null),
    pl: 3,
    ml: 0,
    onClick: newBlank,
    fontSize: {
      base: 'xs',
      md: 'sm',
      lg: 'md'
    },
    "data-cy": 'newProject'
  }, "blank page"), __jsx(Text, {
    size: isSmScreen ? 'sm' : 'md',
    fontSize: {
      base: 'xs',
      md: 'sm',
      lg: 'md'
    },
    color: "gray.900"
  }, "want a fresh start?")));
}