import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { useBreakpointValue } from '@chakra-ui/react';
import { Button, IconButton, ProBadgeIcon, useIsSmallScreen } from '@playful/design_system';
import { useUserContext } from '../user/UserContext';
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import React from 'react';
export var HatchProButton = function HatchProButton(props) {
  var isSmallScreen = useIsSmallScreen();
  var _useUserContext = useUserContext(),
    hasActiveSubscription = _useUserContext.hasActiveSubscription;
  var showSmallButton = useBreakpointValue({
    base: true,
    lg: false
  }, {
    fallback: 'lg'
  });
  var origin = props.origin;
  var onClick = function onClick() {
    subscriptionPaywalls.openModal({
      reason: origin
    });
  };
  var colorScheme = props.colorScheme || 'light';
  var iconFill = colorScheme === 'dark' ? 'yellow.500' : 'purple.500';
  if (hasActiveSubscription) {
    return null;
  }
  if (isSmallScreen || showSmallButton) {
    return __jsx(IconButton, _extends({
      "aria-label": "get pro",
      size: "sm",
      variant: "ghost",
      colorScheme: colorScheme,
      icon: __jsx(ProBadgeIcon, {
        fill: iconFill
      }),
      onClick: onClick
    }, props));
  }
  return __jsx(Button, _extends({
    size: "sm",
    colorScheme: colorScheme,
    leftIcon: __jsx(ProBadgeIcon, {
      fill: iconFill
    }),
    variant: "ghost",
    onClick: onClick
  }, props), "get pro");
};