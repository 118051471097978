import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { forwardRef } from '@chakra-ui/react';
import { Button } from '@playful/design_system';
import React from 'react';
export var CTAButton = forwardRef(function (props, forwardedRef) {
  var children = props.children;
  return __jsx(Button, _extends({
    colorScheme: "yellow",
    variant: 'solid'
  }, props, {
    ref: forwardedRef
  }), children);
});