import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { useBreakpointValue } from '@chakra-ui/react';
import { Box, Button, IconButton, MenuButton, MenuIcon, PlusIcon, useIsSmallScreen } from '@playful/design_system';
import React from 'react';
import { CTAButton } from '../components/CallToAction/CTAButton';
import { MenuSubMenu } from '../components/MenuSubMenu';
import { darkBlankProjectRoute, lightBlankProjectRoute } from '../components/ProjectTemplates';
import { useCleanPath } from '../hooks/useCleanPath';
import { ROUTE_NEW_PROJECT, ROUTE_TEMPLATES } from '../routes';
import { AuthLink } from './AuthFlow/AuthLink';
import { Avatar } from './Avatar';
import ProProfileBadge from './pro-profile-badge.png';
import { useNavigationLinks } from './useNavigationLinks';
import { useUserContext } from './UserContext';
import { useRouter } from '../hooks/useRouter';
var UserSettingsMenuButton = function UserSettingsMenuButton(_ref) {
  var isOpen = _ref.isOpen,
    onOpen = _ref.onOpen;
  var _useUserContext = useUserContext(),
    user = _useUserContext.user,
    hasActiveSubscription = _useUserContext.hasActiveSubscription;
  return __jsx(MenuButton, {
    as: IconButton,
    "data-cy": "account-button",
    "aria-label": "My Account",
    "aria-owns": isOpen ? 'menu-appbar' : undefined,
    "aria-haspopup": "true",
    variant: "ghost",
    onClick: onOpen,
    colorScheme: "whiteAlpha",
    position: "relative",
    isRound: true
  }, __jsx(Avatar, {
    email: user.email,
    userName: user.name
  }), hasActiveSubscription && __jsx(Box, {
    position: "absolute",
    bottom: 0,
    right: '-2px'
  }, __jsx("img", {
    width: 18,
    src: ProProfileBadge.src,
    alt: "Hatch Pro"
  })));
};
var MobileMenuButton = function MobileMenuButton(_ref2) {
  var onOpen = _ref2.onOpen;
  return __jsx(MenuButton, {
    as: IconButton,
    "aria-label": "mobile navigation menu",
    onClick: onOpen,
    colorScheme: "dark",
    variant: "ghost",
    icon: __jsx(MenuIcon, null),
    size: "sm",
    "data-cy": "mobile-menu-button"
  });
};
export function AccountAppBarSection() {
  var _useUserContext2 = useUserContext(),
    isLoggedInUser = _useUserContext2.isLoggedInUser;
  var isSmallScreen = useIsSmallScreen();
  var _useRouter = useRouter(),
    push = _useRouter.push,
    query = _useRouter.query,
    asPath = _useRouter.asPath;
  var cleanedPath = useCleanPath(asPath);
  var _useNavigationLinks = useNavigationLinks(),
    getUserProfileRoutes = _useNavigationLinks.getUserProfileRoutes,
    getAllRoutes = _useNavigationLinks.getAllRoutes;
  var userRoutes = getUserProfileRoutes();
  var allRoutes = getAllRoutes();
  var buttonSize = useBreakpointValue({
    base: 'sm',
    lg: 'md'
  }, {
    fallback: 'lg'
  });

  // TODO: make this whole header more composable for next page purposes and get rid of
  // per-page logic here
  var hideNewProject = (cleanedPath === ROUTE_NEW_PROJECT || cleanedPath === ROUTE_TEMPLATES) && !query.newProject;
  return __jsx(React.Fragment, null, isLoggedInUser ? __jsx(React.Fragment, null, !hideNewProject && __jsx(CTAButton, {
    size: buttonSize,
    color: "gray.900",
    leftIcon: __jsx(PlusIcon, {
      display: {
        base: 'none',
        lg: 'block'
      }
    }),
    pl: {
      base: 2,
      md: 3
    },
    ml: 0,
    onClick: function onClick(event) {
      if (event.shiftKey) {
        // If shift key is held down open a light or dark (hold alt key too) blank project.
        push(event.altKey ? darkBlankProjectRoute : lightBlankProjectRoute);
      } else {
        push({
          query: {
            newProject: true
          }
        }, ROUTE_NEW_PROJECT, {
          shallow: true
        });
      }
    },
    fontSize: {
      base: 'xs',
      md: 'sm',
      lg: 'md'
    },
    "data-cy": 'newProject'
  }, "new project")) : __jsx(React.Fragment, null, __jsx(Button, {
    as: AuthLink,
    "data-cy": "sign-in",
    role: "button",
    authType: 'signIn',
    size: buttonSize,
    fontSize: {
      base: 'sm',
      md: 'md'
    },
    colorScheme: "dark"
  }, "log in"), __jsx(Button, {
    as: AuthLink,
    "data-cy": "sign-up",
    role: "button",
    authType: 'signUp',
    size: buttonSize,
    fontSize: {
      base: 'sm',
      md: 'md'
    },
    colorScheme: "yellow"
  }, "sign up")), isLoggedInUser && userRoutes && !isSmallScreen && __jsx(MenuSubMenu, _extends({
    size: "lg",
    TopMenuButton: UserSettingsMenuButton
  }, userRoutes, {
    placement: "left"
  })), allRoutes && isSmallScreen && __jsx(Box, {
    ml: 2
  }, __jsx(MenuSubMenu, {
    TopMenuButton: MobileMenuButton,
    label: allRoutes.label,
    items: allRoutes.items,
    placement: "bottom-end",
    size: "sm"
  })));
}