import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { useTargetScrolledBehind } from '@playful/design_system';
import { createGenericContext } from '../../utils/createGenericContext';
import React from 'react';
var _createGenericContext = createGenericContext(),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  hook = _createGenericContext2[0],
  Provider = _createGenericContext2[1];
export var useProjectTemplates = hook;
export function ProjectTemplates(_ref) {
  var children = _ref.children,
    containerPadding = _ref.containerPadding;
  // useTargetScrolledBehind's ref needs to be scoped to the modal content
  var _useTargetScrolledBeh = useTargetScrolledBehind(),
    hasShadow = _useTargetScrolledBeh.isBehind,
    scrollTargetRef = _useTargetScrolledBeh.scrollTargetRef;
  return __jsx(Provider, {
    value: {
      containerPadding: containerPadding,
      shadowRef: scrollTargetRef,
      hasShadow: hasShadow
    }
  }, children);
}